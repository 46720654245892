<template>
  <div class="sys-meeting-trouble">
    <div class="btn-area">
      <el-form
        size="small"
        :inline="true"
        :model="listParams"
        class="form-inline"
      >
        <el-form-item label="关键字">
          <el-input
            v-model="listParams.searchContent"
            placeholder="问题描述"
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button v-show="authorities.includes('BACK:BACK_T_MEETING_ROOM_PROBLEM_PAGE_LIST')" @click="handleSearch" type="primary" icon="el-icon-search"
            >搜索</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="list-area">
      <el-table
        v-show="authorities.includes('BACK:BACK_T_MEETING_ROOM_PROBLEM_PAGE_LIST')"
        height="100%"
        :data="troubleList"
        v-loading="loading"
        row-key="id"
        style="width: 100%; min-width: 1200px"
        :header-cell-style="{ background: '#eef1f6' }"
      >
        <el-table-column prop="createTimeString" align="center" label="课堂时间">
        </el-table-column>
        <el-table-column :formatter="formatterPosition" align="center" label="位置">
        </el-table-column>
        <el-table-column prop="userNickname" align="center" label="提交人">
        </el-table-column>
        <el-table-column prop="meetingRoomNo" align="center" label="直播间房间号">
        </el-table-column>
        <el-table-column
          prop="problemContent"
          align="center"
          label="提交内容"
        >
        </el-table-column>

        <el-table-column prop="enable" align="center" label="故障状态">
          <template slot-scope="scope">
            <span>{{ scope.row.problemStatus == 1 ? "已解决" : "未解决" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" align="center" label="操作">
          <template slot-scope="scope">
            <el-link
              v-show="authorities.includes('BACK:BACK_T_MEETING_ROOM_PROBLEM_UPDATE_STATUS')"
              type="primary"
              :underline="false"
              @click="handleResoleProblem(scope.row)"
              >排查</el-link
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="bottom-area">
      <el-pagination
        v-show="authorities.includes('BACK:BACK_T_MEETING_ROOM_PROBLEM_PAGE_LIST')"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNum"
        :page-size="listParams.pageSize"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="问题是否解决"
      center
      class="dialog-problem"
      :visible.sync="dialogProblemStatus"
      width="500px"
    >
      <span slot="footer" class="dialog-footer">
        <el-button
          size="small"
          type="primary"
          @click="handleConfirmProblemStatus(1)"
          >是</el-button
        >
        <el-button class="mL20" size="small" @click="handleConfirmProblemStatus(0)"
          >否</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { meetingProblem, meetingProblemStatus } from "@/api/meetingProblem";
import { mapState } from 'vuex';
export default {
  data() {
    return {
      troubleList: [],
      total: 0,
      loading: false,
      dialogProblemStatus: false,
      selectProblemId: null,
      listParams: {
        pageNum: 1,
        pageSize: 10,
        searchContent: "",
      },
    };
  },
  components: {},
  computed: {
    ...mapState(['authorities']),
  },
  mounted() {
    this.getMeetingProblemList();
  },
  methods: {
    /* 格式化故障位置 */
    formatterPosition(row) {
      switch(row.problemPosition){
        case 1:
          return '通用'
        case 2:
          return '语音'
        case 3:
          return '视频'
        case 4:
          return '白板'
        case 5:
          return '屏幕共享'
      }
    },
    /* 获取问题列表 */
    async getMeetingProblemList() {
      if(!this.authorities.includes('BACK:BACK_T_MEETING_ROOM_PROBLEM_PAGE_LIST')){
        return
      }
      try {
        this.loading = true
        const { total, records, pages, current } = await meetingProblem(this.listParams);
        this.loading = false
        this.total = total;
        this.troubleList = records;
        if(current > pages && pages) {
          this.listParams.pageNum = pages 
          this.getMeetingProblemList()
        }
      } catch (err) {
        this.loading = false
        let msg = err.msg ? err.msg : "获取问题列表发生异常！";
        console.error("meetingProblem: ", err);
        this.msgError(msg);
      }
    },
    /* 点击排查 */
    handleResoleProblem(row) {
      this.selectProblemId = row.id
      this.dialogProblemStatus = true
    },
    /* 保存问题解决状态 */
    async handleConfirmProblemStatus(status) {
      if(this._confirmFlag){
        return
      }
      this._confirmFlag = true
      try {
        await meetingProblemStatus(this.selectProblemId, status);
        this._confirmFlag = false
        this.dialogProblemStatus = false
        this.msgSuccess("保存成功！");
      } catch (err) {
        this._confirmFlag = false
        let msg = err.msg ? err.msg : "改变问题状态发生异常！";
        console.error("meetingProblemStatus: ", err);
        this.msgError(msg);
      }
    },
    /* 搜索 */
    handleSearch() {
      this.listParams.pageNum = 1;
      this.getMeetingProblemList();
    },
    /* 列表页码改变 */
    handleCurrentChange(pageNum) {
      this.listParams.pageNum = pageNum;
      this.getMeetingProblemList();
    },
    /* 列表页面数量改变 */
    handleSizeChange(pageSize) {
      this.listParams.pageSize = pageSize;
      this.getMeetingProblemList();
    },
  },
};
</script>
<style lang='scss' scoped>
.sys-meeting-trouble {
  height: 100%;
  padding: 0 20px 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .btn-area {
    padding: 10px 0 0;
  }
  .list-area {
    flex: 1;
    overflow-x: auto;
  }
  .bottom-area {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dialog-problem{
    .dialog-footer{
      .el-button{
        min-width: 80px;
      }
    }
  }
}
</style>