import request from "../utils/request";
import qs from 'qs'

/* 分页故障分页查询 */
export const meetingProblem = (params) => {
    return request.post(`/back/t/meeting/problem/page/list?${qs.stringify(params, { indices: false })}`)
}

/* 故障解决未解决 */
export const meetingProblemStatus = (id, status) => {
    return request.post(`/back/t/meeting/problem/update/status/${id}/${status}`)
}